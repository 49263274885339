import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ControlPanel from "./pages/ControlPanel";
import { ProductDetails } from "./pages/ProductDetails";
import { LandingPage } from "./pages/LandingPage";
import PrivateRoute from "./components/PrivateRoute";
import ChatInterface from "./pages/ChatInterface";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import NavBar from "./components/NavBar";
import SubMngmt from "./components/SubMgmt";
import { Contact } from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function HomeRedirect() {
  const { user } = useAuthenticator((context) => [context.user]);
  return user ? <Navigate replace to="/controlpanel" /> : <LandingPage />;
}

export function App() {
  return (
    <Authenticator.Provider>
      <Router>
        <div className="min-h-screen bg-gray-900 text-white flex flex-col">
          <NavBar />
          <main>
            <Routes>
              <Route path="/" element={<HomeRedirect />} />
              <Route path="/controlpanel" element={<ControlPanel />} />
              <Route path="/product/:productId" element={<ProductDetails />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              <Route
                path="/subscription-management"
                element={<PrivateRoute element={<SubMngmt />} />}
              />

              <Route
                path="/myai"
                element={<PrivateRoute element={<ChatInterface />} />}
              />
              <Route
                path="/myai1"
                element={
                  <PrivateRoute element={<ChatInterface chatBotId="1" />} />
                }
              />
              <Route
                path="/myai2"
                element={
                  <PrivateRoute element={<ChatInterface chatBotId="2" />} />
                }
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </main>
        </div>
      </Router>
    </Authenticator.Provider>
  );
}

import React, { useState, useRef } from "react";
import { FaMicrophone, FaStop, FaTrash, FaPaperPlane } from "react-icons/fa";
import { Storage } from "aws-amplify";
import { checkAwsCredentials } from "../../utils/websocket";

// Configure Storage with the correct region and level
Storage.configure({
  region: "us-east-1",
  bucket: "sapienslab-storage-dev-user-files",
  level: "public",
  customPrefix: {
    public: "",
  },
});

const AudioRecorder = ({ onAudioUpload, userId, chatBotId }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const timerRef = useRef(null);
  const audioContextRef = useRef(null);
  const scriptProcessorRef = useRef(null);
  const audioDataRef = useRef([]);
  const isUsingCustomRecorderRef = useRef(false);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      if (isSafari) {
        isUsingCustomRecorderRef.current = true;
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioContext = new AudioContext();
        audioContextRef.current = audioContext;
        audioDataRef.current = [];
        const source = audioContext.createMediaStreamSource(stream);
        const bufferSize = 4096;
        const scriptProcessor = audioContext.createScriptProcessor(
          bufferSize,
          1,
          1
        );
        scriptProcessor.onaudioprocess = (e) => {
          const channelData = e.inputBuffer.getChannelData(0);
          // Push a copy of the data
          audioDataRef.current.push(new Float32Array(channelData));
        };
        scriptProcessorRef.current = scriptProcessor;
        source.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);
        setIsRecording(true);
        setRecordingTime(0);
        timerRef.current = setInterval(() => {
          setRecordingTime((prev) => prev + 1);
        }, 1000);
      } else {
        isUsingCustomRecorderRef.current = false;
        let options = { mimeType: "audio/mp4" };
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          options = { mimeType: "audio/webm;codecs=opus" };
        }
        const chosenMimeType = options.mimeType;
        mediaRecorderRef.current = new MediaRecorder(stream, options);
        chunksRef.current = [];
        mediaRecorderRef.current.ondataavailable = (e) => {
          if (e.data.size > 0) {
            chunksRef.current.push(e.data);
          }
        };
        mediaRecorderRef.current.onstop = () => {
          const blob = new Blob(chunksRef.current, { type: chosenMimeType });
          setAudioBlob(blob);
          stream.getTracks().forEach((track) => track.stop());
        };
        mediaRecorderRef.current.start();
        setIsRecording(true);
        setRecordingTime(0);
        timerRef.current = setInterval(() => {
          setRecordingTime((prev) => prev + 1);
        }, 1000);
      }
    } catch (error) {
      console.error("Error accessing microphone:", error);
      alert(
        "Error accessing microphone. Please ensure you have granted microphone permissions."
      );
    }
  };

  const stopRecording = () => {
    if (isUsingCustomRecorderRef.current) {
      let sampleRate = audioContextRef.current
        ? audioContextRef.current.sampleRate
        : 44100;
      if (scriptProcessorRef.current) {
        scriptProcessorRef.current.disconnect();
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      const wavBlob = exportWAV(audioDataRef.current, sampleRate, 1);
      setAudioBlob(wavBlob);
      setIsRecording(false);
      clearInterval(timerRef.current);
    } else {
      if (mediaRecorderRef.current && isRecording) {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
        clearInterval(timerRef.current);
      }
    }
  };

  const cancelRecording = () => {
    if (isUsingCustomRecorderRef.current) {
      if (scriptProcessorRef.current) {
        scriptProcessorRef.current.disconnect();
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      audioDataRef.current = [];
      setAudioBlob(null);
      setRecordingTime(0);
      clearInterval(timerRef.current);
    } else {
      if (mediaRecorderRef.current && isRecording) {
        mediaRecorderRef.current.stop();
        setAudioBlob(null);
        setRecordingTime(0);
        clearInterval(timerRef.current);
      }
    }
  };

  const uploadAudio = async () => {
    if (!audioBlob) return;

    const timestamp = Date.now();
    let extension = "mp4";
    if (audioBlob.type === "audio/mp4") {
      extension = "mp4";
    } else if (audioBlob.type === "audio/webm") {
      extension = "webm";
    } else if (audioBlob.type === "audio/wav") {
      extension = "wav";
    }
    const s3Key = `public/users/${userId}/chatbots/${chatBotId}/audio/${timestamp}.${extension}`;
    const bucketName = "sapienslab-storage-dev-user-files";

    try {
      // Check AWS credentials before uploading
      const credentials = await checkAwsCredentials();
      console.log("Using credentials:", credentials);

      const result = await Storage.put(s3Key, audioBlob, {
        contentType: audioBlob.type,
        metadata: {
          userId,
          chatBotId,
          uploadDate: new Date().toISOString(),
        },
      });

      // Instead of getting a signed URL, construct the S3 path
      const s3Path = `s3://${bucketName}/${s3Key}`;

      onAudioUpload({
        key: s3Key,
        type: audioBlob.type,
        name: `audio_${timestamp}.${extension}`,
        path: s3Key,
        fullPath: s3Path,
        duration: recordingTime,
      });

      setAudioBlob(null);
      setRecordingTime(0);
    } catch (error) {
      console.error("Error uploading audio:", error);
      alert("Error uploading audio message: " + error.message);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  const exportWAV = (buffers, sampleRate, channelCount) => {
    const totalLength = buffers.reduce((acc, curr) => acc + curr.length, 0);
    const result = new Float32Array(totalLength);
    let offset = 0;
    buffers.forEach((buffer) => {
      result.set(buffer, offset);
      offset += buffer.length;
    });

    const bufferLength = result.length;
    const wavBuffer = new ArrayBuffer(44 + bufferLength * 2);
    const view = new DataView(wavBuffer);

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    // RIFF header
    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + bufferLength * 2, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, channelCount, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * channelCount * 2, true);
    view.setUint16(32, channelCount * 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, "data");
    view.setUint32(40, bufferLength * 2, true);

    let offset2 = 44;
    for (let i = 0; i < result.length; i++) {
      let s = Math.max(-1, Math.min(1, result[i]));
      view.setInt16(offset2, s < 0 ? s * 0x8000 : s * 0x7fff, true);
      offset2 += 2;
    }
    return new Blob([view], { type: "audio/wav" });
  };

  return (
    <div className="flex items-center gap-2">
      {!isRecording && !audioBlob && (
        <button
          onClick={startRecording}
          className="p-2 text-gray-400 hover:text-gray-200 transition-colors"
          title="Record audio message"
        >
          <FaMicrophone size={20} />
        </button>
      )}

      {isRecording && (
        <div className="flex items-center gap-2 bg-gray-800 rounded-full px-3 py-1">
          <div className="animate-pulse text-red-500">●</div>
          <span className="text-sm text-white">
            {formatTime(recordingTime)}
          </span>
          <button
            onClick={stopRecording}
            className="p-1 text-gray-200 hover:text-white"
            title="Stop recording"
          >
            <FaStop size={16} />
          </button>
          <button
            onClick={cancelRecording}
            className="p-1 text-gray-400 hover:text-red-400"
            title="Cancel recording"
          >
            <FaTrash size={16} />
          </button>
        </div>
      )}

      {audioBlob && !isRecording && (
        <div className="flex items-center gap-2 bg-gray-800 rounded-full px-3 py-1">
          <span className="text-sm text-white">
            {formatTime(recordingTime)}
          </span>
          <button
            onClick={uploadAudio}
            className="p-1 text-blue-400 hover:text-blue-300"
            title="Send audio message"
          >
            <FaPaperPlane size={16} />
          </button>
          <button
            onClick={cancelRecording}
            className="p-1 text-gray-400 hover:text-red-400"
            title="Cancel"
          >
            <FaTrash size={16} />
          </button>
        </div>
      )}
    </div>
  );
};

export default AudioRecorder;

import React from "react";
import ReactMarkdown from "react-markdown";
import jarvisImage from "../../img/jarvis_ai_sapiens_small.png";
import samImage from "../../img/sam_ai_sapiens_small.jpg";
import { FaUser } from "react-icons/fa";
import CodeBlock from "./CodeBlock";
import AudioPlayer from "./AudioPlayer";
import AudioPlayerOutput from "./AudioPlayerOutput";

const TypingIndicator = ({ chatBotId }) => (
  <div className="flex items-start gap-2">
    <img
      src={chatBotId === "0" ? jarvisImage : samImage}
      alt="AI Avatar"
      className="w-7 h-7 rounded-full object-cover"
    />
    <div className="bg-gray-800 rounded-lg p-3 max-w-[80px]">
      <div className="flex space-x-1">
        <div
          className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
          style={{ animationDelay: "0ms" }}
        ></div>
        <div
          className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
          style={{ animationDelay: "150ms" }}
        ></div>
        <div
          className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
          style={{ animationDelay: "300ms" }}
        ></div>
      </div>
    </div>
  </div>
);

const MessageList = ({ messages, messagesEndRef, chatBotId, isTyping }) => {
  const parseMessage = (messageText) => {
    // Handle null or undefined messageText
    if (!messageText) {
      return { text: "", attachment: null };
    }

    // Check for audio output format first
    const audioOutputRegex =
      /\[audio\]\((s3:\/\/sam-bucket-assets\/.*?\.wav)\)/;
    const audioOutputMatch = messageText.match(audioOutputRegex);
    if (audioOutputMatch) {
      return {
        text: messageText.replace(audioOutputMatch[0], "").trim(),
        attachment: {
          url: audioOutputMatch[1],
          type: "audio/output",
          isAudioOutput: true,
        },
      };
    }

    // Then check for file attachments
    const fileRegex = /\[File: (.*?)\]\((.*?)\)/;
    const match = messageText.match(fileRegex);

    if (match) {
      const fileName = match[1];
      const fileUrl = match[2];
      const lowerCaseFileName = fileName.toLowerCase();
      const isPdf = lowerCaseFileName.endsWith(".pdf");
      const isWebm = lowerCaseFileName.endsWith(".webm");
      const isMp4 = lowerCaseFileName.endsWith(".mp4");
      const isWav = lowerCaseFileName.endsWith(".wav");
      const duration = messageText.match(/\[duration: (\d+)\]/)?.[1];

      return {
        text: messageText
          .replace(match[0], "")
          .replace(/\[duration: \d+\]/, "")
          .trim(),
        attachment: {
          name: fileName,
          url: fileUrl,
          type: isPdf
            ? "application/pdf"
            : isWebm
              ? "audio/webm"
              : isMp4
                ? "audio/mp4"
                : isWav
                  ? "audio/wav"
                  : "unknown",
          isPDF: isPdf,
          isAudio: isWebm || isMp4 || isWav,
          duration: duration ? parseInt(duration) : null,
        },
      };
    }

    // If no file attachment, check for URLs but ignore markdown image syntax
    const urlRegex = /(?<!!)\[(.*?)\]\((https?:\/\/[^\s)]+)\)/g;
    const formattedText = messageText.replace(urlRegex, (match, text, url) => {
      // Only format if it's not an image (doesn't start with !)
      if (!match.startsWith("![")) {
        return `[${text}](${url})`;
      }
      return match;
    });

    return { text: formattedText, attachment: null };
  };

  const renderMessage = (message) => {
    // Handle null or undefined message
    if (!message || !message.msg) {
      message = {
        ...message,
        msg: "Error: Empty message received",
      };
    }

    console.log("Original message:", message);
    const parsedMessage = parseMessage(message.msg);
    console.log("Parsed message:", parsedMessage);

    const isPDF = parsedMessage.attachment?.type === "application/pdf";
    const isAudio =
      parsedMessage.attachment?.type === "audio/webm" ||
      parsedMessage.attachment?.type === "audio/mp4" ||
      parsedMessage.attachment?.type === "audio/wav";
    const isAudioOutput = parsedMessage.attachment?.type === "audio/output";

    return (
      <div
        key={message.id}
        className={`flex items-start ${
          message.type === "human" ? "justify-end" : "justify-start"
        } gap-2`}
      >
        {message.type !== "human" && (
          <img
            src={chatBotId === "0" ? jarvisImage : samImage}
            alt="AI Avatar"
            className="w-7 h-7 rounded-full object-cover"
          />
        )}
        <div
          className={`max-w-[80%] rounded-lg p-2.5 ${
            message.type === "human"
              ? message.msg === "Error: Empty message received"
                ? "bg-red-600 text-white"
                : "bg-blue-600 text-white"
              : "bg-gray-800 text-white"
          }`}
        >
          {parsedMessage.text && (
            <div className="prose prose-invert prose-sm max-w-none prose-p:my-1 prose-ul:my-1 prose-li:my-0.5 prose-headings:my-2 prose-pre:text-sm prose-code:text-sm leading-relaxed font-[system-ui]">
              <ReactMarkdown
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    const language = match ? match[1] : "";
                    return !inline && match ? (
                      <CodeBlock language={language}>
                        {String(children).replace(/\n$/, "")}
                      </CodeBlock>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                  p: ({ node, children }) => (
                    <p className="text-sm leading-relaxed">{children}</p>
                  ),
                  li: ({ node, children }) => (
                    <li className="text-sm leading-relaxed">{children}</li>
                  ),
                  a: ({ node, href, children, ...props }) => (
                    <a
                      href={href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:text-blue-300 underline text-sm"
                      {...props}
                    >
                      {children}
                    </a>
                  ),
                  img({ node, src, alt, ...props }) {
                    return (
                      <img
                        src={src}
                        alt={alt}
                        className="max-w-full h-auto rounded-lg my-2"
                        {...props}
                      />
                    );
                  },
                }}
              >
                {parsedMessage.text}
              </ReactMarkdown>
            </div>
          )}

          {parsedMessage.attachment && isPDF && (
            <div className="mt-2 flex items-center gap-2 p-2 rounded-lg bg-gray-700">
              <div className="w-6 h-6 flex items-center justify-center bg-pink-500 rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <a
                href={parsedMessage.attachment.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs text-blue-400 hover:underline"
              >
                {parsedMessage.attachment.name}
              </a>
            </div>
          )}

          {parsedMessage.attachment && isAudio && (
            <div className="mt-2">
              <AudioPlayer
                audioUrl={parsedMessage.attachment.url}
                duration={parsedMessage.attachment.duration}
              />
            </div>
          )}

          {parsedMessage.attachment && isAudioOutput && (
            <div className="mt-2">
              <AudioPlayerOutput audioUrl={parsedMessage.attachment.url} />
            </div>
          )}
        </div>
        {message.type === "human" && (
          <div className="w-7 h-7 rounded-full bg-gray-600 flex items-center justify-center">
            <FaUser className="text-white text-xs" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-2 p-4 text-sm">
      {messages.map(renderMessage)}
      {isTyping && <TypingIndicator chatBotId={chatBotId} />}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;

import React, { useState, useEffect } from "react";
import {
  Authenticator,
  ThemeProvider,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { theme } from "../theme";
import Sidebar from "../components/Sidebar";
import ChatInterface from "./ChatInterface";
import axios from "axios";
import { getUserId } from "../utils/userUtils";

// Amplify is already configured in index.js with the correct configuration

function ControlPanelContent({ user, signOut }) {
  const [selectedChatBotId, setSelectedChatBotId] = useState(null);
  const [subscribedProducts, setSubscribedProducts] = useState([]);

  // Function to save the selected bot to localStorage
  const saveSelectedBot = (botId) => {
    localStorage.setItem("lastSelectedBot", botId);
  };

  // Function to get the last selected bot from localStorage
  const getLastSelectedBot = () => {
    return localStorage.getItem("lastSelectedBot");
  };

  useEffect(() => {
    if (!user) return; // Ensure the user is available

    const fetchSubscriptions = async () => {
      try {
        const userId = getUserId(user);

        if (!userId) {
          console.error("No user ID available");
          setSubscribedProducts([]);
          return;
        }

        const response = await axios.get(
          `https://d4gscm5o97.execute-api.us-east-1.amazonaws.com/prod/subscriptions/${userId}`
        );

        const subscription = response.data;

        // Initialize activeSubscription outside the if-else blocks
        let activeSubscription = [];

        // Check if the subscription is active
        if (subscription.subscription_status === "active") {
          // Access the allowed_bots array
          const allowedBots = subscription.allowed_bots || [];
          console.log("Allowed Bots:", allowedBots);
          activeSubscription = allowedBots;
        } else {
          console.log("No active subscription found.");
          activeSubscription = [];
        }

        setSubscribedProducts(activeSubscription);

        // Get the last selected bot from localStorage
        const lastSelectedBot = getLastSelectedBot();

        // Check if the last selected bot is in the active subscriptions
        if (lastSelectedBot && activeSubscription.includes(lastSelectedBot)) {
          setSelectedChatBotId(lastSelectedBot);
        } else if (activeSubscription.length > 0) {
          // Fall back to selecting the first available bot
          setSelectedChatBotId(activeSubscription[0]);
          saveSelectedBot(activeSubscription[0]);
        }
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
        setSubscribedProducts([]);
      }
    };

    fetchSubscriptions();
  }, [user]);

  // Wrap the original setSelectedChatBotId to also save to localStorage
  const handleBotSelection = (botId) => {
    setSelectedChatBotId(botId);
    saveSelectedBot(botId);
  };

  return (
    <div className="bg-gray-900 text-white">
      <Sidebar
        user={user}
        setSelectedChatBotId={handleBotSelection}
        subscribedProducts={subscribedProducts}
      />
      <div className="grid-cols-12">
        <div className="sm:col-span-12 bg-gray-800 shadow-lg p-2">
          {selectedChatBotId ? (
            <ChatInterface chatBotId={selectedChatBotId} />
          ) : (
            <h2 className="text-2xl font-bold mb-6">Please select a ChatBot</h2>
          )}
        </div>
      </div>
    </div>
  );
}

export default function ControlPanel() {
  return (
    <ThemeProvider theme={theme}>
      <Authenticator
        loginMechanisms={["email"]}
        socialProviders={["google"]}
        initialState="signIn"
        services={{
          handleSignIn: async (formData) => {
            const { username, password } = formData;
            return Amplify.Auth.signIn({
              username,
              password,
            });
          },
        }}
      >
        {({ signOut, user }) =>
          user ? (
            <ControlPanelContent user={user} signOut={signOut} />
          ) : (
            <div>Loading...</div>
          )
        }
      </Authenticator>
    </ThemeProvider>
  );
}

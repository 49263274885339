import React, { useState, useRef, useEffect } from "react";
import { FaPlay, FaPause, FaDownload, FaEllipsisV } from "react-icons/fa";
import { Storage } from "aws-amplify";

const AudioPlayer = ({ audioUrl, duration }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [audioSrc, setAudioSrc] = useState(null);
  const audioRef = useRef(new Audio());
  const progressRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const fetchAudio = async () => {
      try {
        let signedUrl = audioUrl;
        // If it's an S3 URL, get a signed URL
        if (audioUrl.startsWith("s3://")) {
          // Just remove the s3://bucket-name/ prefix to get the key
          const s3Key = audioUrl.replace(
            "s3://sapienslab-storage-dev-user-files/",
            ""
          );
          signedUrl = await Storage.get(s3Key);
        }
        setAudioSrc(signedUrl);
        audioRef.current.src = signedUrl;
      } catch (error) {
        console.error("Error fetching audio:", error);
      }
    };

    fetchAudio();

    return () => {
      audioRef.current.pause();
      audioRef.current.src = "";
    };
  }, [audioUrl]);

  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
      audio.currentTime = 0;
    };

    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("ended", handleEnded);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("ended", handleEnded);
    };
  }, []);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgressClick = (e) => {
    const progressBar = progressRef.current;
    const rect = progressBar.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percentage = x / rect.width;
    const newTime = percentage * (duration || audioRef.current.duration);
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <div className="relative flex flex-col gap-2">
      <div className="relative flex items-center gap-3 bg-gray-800 rounded-lg p-2 max-w-xs">
        <button
          onClick={togglePlayPause}
          className="w-8 h-8 flex items-center justify-center bg-purple-600 rounded-full hover:bg-purple-700 transition-colors"
        >
          {isPlaying ? (
            <FaPause size={12} />
          ) : (
            <FaPlay size={12} className="ml-1" />
          )}
        </button>

        <div className="flex-grow">
          <div
            ref={progressRef}
            onClick={handleProgressClick}
            className="h-1 bg-gray-600 rounded-full cursor-pointer"
          >
            <div
              className="h-full bg-purple-600 rounded-full relative"
              style={{
                width: `${(currentTime / (duration || audioRef.current.duration)) * 100 || 0}%`,
              }}
            >
              <div className="absolute right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2 w-2.5 h-2.5 bg-purple-600 rounded-full" />
            </div>
          </div>
          <div className="flex justify-between text-xs text-gray-400 mt-1">
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(duration || 0)}</span>
          </div>
        </div>

        {/* Three dot menu button */}
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="absolute top-1 right-1 text-white p-1 hover:bg-gray-700 rounded-full"
        >
          <FaEllipsisV size={16} />
        </button>

        {menuOpen && audioSrc && (
          <div className="absolute top-8 right-1 bg-gray-800 text-white rounded shadow-md">
            <a
              href={audioSrc}
              download
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setMenuOpen(false)}
              className="block p-2 hover:bg-gray-700 rounded-full"
            >
              <FaDownload size={14} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioPlayer;
